// Font family
$font-base: 		    'Archivo', sans-serif;
$font-hedding:		  'Archivo', sans-serif;

// Colors
$body-color: 				#676c71; // body-text
$primary:    				#2da50d;
$white:		   				#ffffff;
$gray-1:			 		  #f6f6f6; 	// bg light
$gray-2:			 		  #dfdfdf;
$gray-3:			 		  #626262;
$gray-4:			 		  #707173;
$gray-5:			 		  #3a4957;
$gray-6:			 		  #293745;
$gray-7: 		 				#1b1b29;
$gray-8: 		 				#022d62; 	// hedding color
$gray-9: 		 				#022d62; 	// bg color
$black: 	 	 				#000000;

// Border color
$border-color: 			#eeeeee;

// For button and input border radius
$border-radius      : 5px;
$border-radius-18 	: 18px;
$border-radius-50px	: 50px;
$border-radius-50 	: 50%;
$border-radius-100 	: 100%;

// Box shadow sizes
$box-shadow         : 2px 2px 4px 0px rgba(2, 45, 98, 0.1);
$boxshadow-lg       : 5px 5px 24px 0px rgba(2, 45, 98, 0.1);

